<template>
  <div v-loading="loading" style="padding: 0 20px">
    <!-- 顶部图片 -->
    <div>
      <img src="@/assets/logo.png" style="height: 72px; width: 72px;">
      <h1>萝卜超答题器</h1>
    </div>
    <el-row :gutter="0" justify="center">
      <el-col :xs="22" :lg="8" style="display: flex;">
        <el-input v-model="query" clearable placeholder="模糊关键字" size="large" style="font-size: 16px;"/>
        <el-button size="large" type="primary" @click="search" style="margin-left: 20px;">查询</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="20" justify="center">
      <el-col :xs="22" :lg="12" v-for="(result, index) in results" :key="index">
        <el-card style="margin-top: 20px;">
          <h3>{{ result.title }}</h3>
          <p>{{ result.description }}</p>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      query: '',  // 搜索输入框内容
      results: [], // 搜索结果列表
      loading: false // 加载状态
    };
  },
  methods: {
    search() {
      if (!this.query) {
        this.results = [];
        return;
      }
      this.loading = true;
      // 调用接口查询
      axios.get(`https://ld.ldxy2.com/prod-api/ldxy/openapi/question`, {
        params: {
          question_lk: this.query
        }
      }).then(response => {
        // 处理返回的数据
        this.results = response.data.map(item => ({
          title: item.question || 'No title',
          description: item.answer || 'No description'
        }));
      }).catch(error => {
        console.error('Error fetching search results:', error);
        this.results = [];
      }).finally(() => {
        this.loading = false;
      });
    },
    reset() {
      // 重置搜索框和结果
      this.query = '';
      this.results = [];
    }
  }
};
</script>

<style scoped>

</style>
